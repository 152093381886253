import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import Title from "../../components/title"
// import Hero from "../../blocks/Hero"
import { FixedBanner } from "../../blocks/Banner"
import TextWithImage from "../../blocks/TextWithImage"

const GigEconomy = () => (
  <Layout>
    {/* <Hero
      data={{
        title: "Paybase and the gig economy",
        copy:
          '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur non lorem ultrices lobortis. Donec in mauris sed eros volutpat tempor eget nec purus. Duis sagittis tempor volutpat. Pellentesque ornare justo in felis efficitur, non semper nisi imperdiet. Morbi rutrum tellus nisl."',
        companyLogo: {
          src: "/who-is-it-for/marketplace/airbnb.svg",
          alt: "John Smith, Product manager at Fast banana",
          copy: "John Smith, Product manager at Fast banana",
        },
        image: {
          src: "/who-is-it-for/gig-economy/img1.svg",
          alt: "placeholder image",
        },
        color1: "green",
        color2: "rose",
        color3: "blue",
      }}
    /> */}
    <Helmet>
      <title>Paybase - Gig Economy</title>
      <meta property="og:title" content="Paybase - Gig Economy" />
    </Helmet>
    <TextWithImage
      data={{
        title: "Paybase for Gig Economy Platforms",
        content:
          "Route payments between your users and gig workers in under 10 seconds whilst automatically receiving your commission fee as the platform! No longer will your gig workers have to wait 10 days for payments to clear. Paybase lets gig workers withdraw to their bank accounts the moment they complete their gig, or have their balance withdrawn automatically at a time that suits you! No operational overhead. No reconciliation. No contest. Paybase handles everything whilst you get paid.",
        image: {
          src: "/who-is-it-for/gig-economy/img1.svg",
          alt: "placeholder alt",
        },
        links: [
          { copy: "Get in touch", to: "https://trustshare.co" },
          {
            copy: "Developer Centre",
            to: "https://developers.paybase.io/recipes/gig-economy",
            color: "white",
            bg: "black",
            shadow: "white",
            hoverColor: "black",
          },
        ],
      }}
      options={{
        titleColor: "blue",
        copyColor: "grey",
        backgroundColor: "lightblue",
        padding: "3rem",
      }}
    />
    <Title copy="Using Paybase’s capabilities on your gig economy platform?" />
    <TextWithImage
      data={{
        title: "Instant End-To-End Payments_",
        content:
          "Never make your users wait for payment. Unlike most providers that force gig workers to wait up to 10 days for each payment to clear, the Paybase solution enables you to pay out as soon as a gig has been completed. Our market-leading solution therefore enables gig workers to spend their earnings immediately - this encourages loyalty to your platform, reduces lead leakage and assures that you stand out from your competitors.",
        image: {
          src: "/who-is-it-for/gig-economy/img2.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#instant-payout" }],
      }}
      options={{ isTextRight: true, titleColor: "rose" }}
    />
    <TextWithImage
      data={{
        title: "White Label Escrow_",
        content:
          "Introduce an escrow service to protect both your gig workers and users. If you are a platform for tradesmen, for instance, you may want payments to be held until a job is completed. If this is a longer-term job such as building a conservatory, parts of the agreed funds can be released in stages as the project progresses. This offers the ultimate level of security with no negative impact on UX for either party.",
        animation: "/animations/homepage/escrow.json",
        links: [{ copy: "Find out more", to: "/what-we-offer#escrow" }],
      }}
      options={{ isTextRight: false, titleColor: "blue" }}
    />
    <TextWithImage
      data={{
        title: "Referral Campaigns_",
        content:
          "Incentivise users to join your platform by setting up a referral campaign. If you are a platform for babysitters, you may wish to offer parents/guardians 50% off their next session for introducing the platform to a friend, with the friend also receiving 50% off their first session. The babysitter is still paid full price, with the remaining 50% of their fee being automatically debited from your platform account. No reconciliation required.",
        image: {
          src: "/who-is-it-for/gig-economy/img4.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#reward" }],
      }}
      options={{ isTextRight: true, titleColor: "darkgreen" }}
    />
    <FixedBanner />
  </Layout>
)

export default GigEconomy
